
import axios from 'axios'

export const getNewsList = (page, limit) => {
    return axios({
        baseURL: 'https://dl-flight.com',
        method: 'get',
        url: '/api/news?page=' + page + '&limit=' + limit
    });
}

export const getNews = (id) => {
    return axios({
        baseURL: 'https://dl-flight.com',
        method: 'get',
        url: '/api/news/' + id
    });
}
